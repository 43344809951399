export enum UsageInstructionForDuration {
  GRADUALLY_REDUCE = 'gradually-reduce',
  STOP = 'stop',
}

export interface Medicine {
  name: string;
  usageInstruction: string | null;
  notes: string | null;
  ratio: number | null;
  groupId: number | null;
  beforeAfterMeal: string | null;
  durationUnit: string | null;
  duration: number | null;
  usageInstructionForDuration: UsageInstructionForDuration | null;
}
