// this is a super class, hence only the core and avatar are provided

import { ConsultationMessageType } from './consultation-message-type';

export interface ConsultationMessageCore {
  consultationMessageId: string;
  title: string | null;
  type: ConsultationMessageType;
  text: string;
  imageUrls: string[];
}

export interface ConsultationMessageAvatar {
  consultationMessageId: string;
  text: string;
  imageUrls: string[];
}
