// this is a super class, hence only the core and avatar are provided

import { UserRole } from './user-role';
import { UserStatus } from './user-status';

export interface UserCore {
  userId: string;
  name: string;
  pictureUrl: string | null;
  email: string | null;
  mobileNumber: string | null;
  role: UserRole;
  /**
   * `UserStatus.Disabled` is not used with Doctors and Channeling-Partners.
   *
   * Reason is that setting the status to Disabled would take away any history that was kept regarding the user.
   * Therefore, `UserStatus.Disabled` should only be used with users that utilize only the `UserStatus.Active`
   * and `UserStatus.Disabled` statuses. However, to provide a simpler UI for the users, `disabled` attribute
   * can be utilized.
   */
  status: UserStatus;
  claims: string[];

  disabled: boolean;
}

export interface UserAvatar {
  userId: string;
  name: string;
  pictureUrl: string | null;
  role: UserRole;
}
